/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { formatDistanceToNowStrict } from "date-fns";
import "../layout.css";

const Layout = ({ children, dayOf, isToday }) => (
  <div className="css-zM5T3cR8wIQ-main">
    <div className="css-zM5T3cR8wIQ-left" />
    <div className="css-zM5T3cR8wIQ-right" />
    <div style={{ textAlign: "center", padding: "0 16px" }}>
      <h1 className="css-zM5T3cR8wIQ-names">
        Daniel <div className="last">King</div>
        <div className="and"> & </div>
        Kasey <div className="last">Michelle</div>
      </h1>
      <div
        className="css-zM5T3cR8wIQ-date"
        style={{
          lineHeight: "1",
          fontFamily: "Lora",
          color: "#565458",
          fontSize: "20px",
          fontWeight: "400",
          textTransform: "uppercase",
          letterSpacing: "2px",
          margin: "8px 0",
        }}
      >
        SATURDAY, THE EIGHTEENTH OF JUNE
        <br />
        TWO THOUSAND TWENTY-TWO
        <div style={{ paddingTop: 16 }}>
          {isToday
            ? `TODAY!`
            : dayOf
            ? `${formatDistanceToNowStrict(dayOf, {
                unit: "day",
              })} TO GO!`
            : ""}
        </div>
      </div>
    </div>
    <div style={{ margin: "auto", maxWidth: 1024, padding: "0 20px" }}>
      <main>{children}</main>
      <footer
        style={{
          textAlign: "center",
          width: "100%",
          bottom: 0,
          fontSize: 14,
        }}
      >
        © {new Date().getFullYear()} TheBigDay.Live
      </footer>
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
