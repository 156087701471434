import React from "react";

export const LiveStream = () => {
  const [[width, height], setSize] = React.useState([600, 337.5]);
  const containerRef = React.useRef(null);

  const setNewSize = React.useCallback(() => {
    if (!containerRef.current) {
      return;
    }
    const dim = containerRef.current.getBoundingClientRect();
    const newWidth = dim.width;
    const newHeight = newWidth * 0.5625;
    window.requestAnimationFrame(() => setSize([newWidth, newHeight]));
  }, []);

  React.useEffect(() => {
    setNewSize();
    window.addEventListener("resize", setNewSize);
    return () => window.removeEventListener("resize", setNewSize);
  }, [setNewSize]);

  return (
    <div ref={containerRef}>
      <iframe
        width={width}
        height={height}
        src="https://www.youtube.com/embed/zM5T3cR8wIQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <div style={{ textAlign: "center" }}>
        or <a href="https://youtu.be/zM5T3cR8wIQ">click here</a> to watch on
        YouTube.
      </div>
    </div>
  );
};
