import React from "react";
import Layout from "../components/zM5T3cR8wIQ/layout";
import { LiveStream } from "../components/zM5T3cR8wIQ/livestream";
import { AddToCal } from "../components/AddToCalendar";
import { RsvpForm } from "../components/zM5T3cR8wIQ/RsvpForm";
import { addDays } from "../utils/addDays";
import { Helmet } from "react-helmet";

const date = new Date();
const dayOf = new Date("6/18/2022 00:00 GMT-0500");
const dayAfter = addDays(dayOf, 1);
const isToday = new Date() >= dayOf && new Date() < dayAfter;

export const ZM5T3cR8wIQ = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Daniel King and Kasey Michelle LIVE 💍 Wedding Ceremony - JUNE 18,
          2022 • 10AM CST
        </title>
        <link rel="canonical" href="https://thebigday.live/zM5T3cR8wIQ" />
      </Helmet>
      {isToday ? (
        <LiveStream />
      ) : (
        <Layout date={date} dayOf={dayOf} isToday={isToday}>
          <div style={{ maxWidth: 500, margin: "auto", padding: "0 8px" }}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin="true"
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Lora&display=swap"
              rel="stylesheet"
            />
            <AddToCal
              id="zM5T3cR8wIQ"
              time="10:00am CST"
              gmailTemplate="https://calendar.google.com/event?action=TEMPLATE&tmeid=NDByOTg1aDM4MnNvdW9kMjg3bmU0ZHUyaTMgamtpbmd3b3JraW5nQG0&tmsrc=jkingworking%40gmail.com"
            />
            <br />
            <RsvpForm />
            <a
              href="https://www.amazon.com/wedding/share/kingwedding618"
              target="_blank"
              rel="noopener noreferrer"
              className="cal-btn"
            >
              Shop Registry
            </a>
          </div>
        </Layout>
      )}
    </>
  );
};

export default ZM5T3cR8wIQ;
